.mainWrap{
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px;
    box-sizing: border-box;
}
.wrapBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 20px 0 20px;
}

.flexBlock{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
    /*align-items: center;*/
}
.group{
    margin-bottom: 20px;
}

.groupElem{
    display: flex;
    justify-self: start;
    align-items: center;
    margin: 10px;
}

.groupElem div{
    margin-right: 10px;
}

.textAreaWidth, .inputText, .selectWidth{
    width: 800px;
}

.bottonBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.flexBlockElem{
    width: 50%;
}

.auctionsLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.auctionsLineElem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonExitDocument{
    width: 300px;
}

.editButton{
    margin-right: 25px;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemsId{
    margin-right: 15px;
}


.groupElemCategory select{
    width: 742px;
}
@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.flexBlock{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around;
    /*align-items: center;*/
}
.group{
    margin-bottom: 20px;
}

.groupElem{
    display: flex;
    justify-self: start;
    align-items: center;
    margin: 10px;
    width: 100%;
}

.groupElem div{
    margin-right: 10px;
}

.textAreaWidth, .inputText, .selectWidth{
    width: 100%;
}

.bottonBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.flexBlockElem{
    width: 50%;
}

.auctionsLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.auctionsLineElem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonExitDocument{
    width: 300px;
}

.editButton{
    margin-right: 25px;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemsId{
    margin-right: 15px;
}

.groupElemCategory select{
    width: 100%;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.blokInputs{
    width: 100%;
    border: 1px solid #c5c5c5;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.editButtonTwo{
    justify-content: space-between;
}

.blokCVZU{
    width: 100%;
    border: 1px solid #c5c5c5;
    padding-top: 5px;
    padding-bottom: 5px;
}

.blokCVZUtitle{
    width: 100%;
    text-align: center;
}

.groupElem div, inPutWrap{
    width: 100%;
}



/************************************************/
/* JUST INPUT */
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    align-items: center;
    justify-content: left;
    min-height: 30px;
    margin-bottom: 15px;
}
.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
}
.inPutWrapColumn{
    width: 100%;
    display: flex;
    flex-direction: column;
    position:relative;
}

p.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0px 0 -10px 10px;
    top: -32px;
    position: relative;
}

/* Check */

.check {
    padding-left: 30px;
}
.option {
    display: block;
    /* margin-bottom: 0.5em; */
}
.disabled{
    color: #92929D;
}

.checkInput {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.checkBox {
    display: flex;
    position: absolute;
    margin-top: 0.2em;
    margin-top: -7px;
    margin-left: -30px;
    width: 16px;
    height: 16px;
    overflow: hidden;
    border: 1px solid #B5B5BE;
    border-radius: 0.05em;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 4px;
    cursor: pointer;
    /* box-shadow: 0 0 0 0.1em #4A90E2; */
}

/* Checked */

.checkInput:checked + .checkBox {
    margin-top: -7px;
    border: 1px solid #139DD9;
    background-color: #139DD9;
    background-position: 50% 50%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.70251 2.7801C1.30947 2.39211 0.676317 2.39621 0.28833 2.78925C-0.0996578 3.1823 -0.095558 3.81545 0.297487 4.20344L2.83844 6.7117C3.25647 7.12434 3.93843 7.08954 4.31227 6.63648L8.43799 1.63648C8.78949 1.21049 8.7291 0.580209 8.30311 0.228708C7.87713 -0.122792 7.24685 -0.0624085 6.89535 0.363579L3.46543 4.52033L1.70251 2.7801Z' fill='white'/%3e%3c/svg%3e ");
}

/* Focused */

.checkInput:focus + .checkBox {
    border: 1px solid #139DD9;
}

/* Disabled */

.checkInput:disabled + .checkBox {
    cursor: default;
    /* box-shadow: 0 0 0 0.1em #9B9B9B; */
}

.checkInput:checked:disabled + .checkBox {
    border: 1px solid #B5B5BE;
    background-color: #9B9B9B;
}
