.tab__item{
    width: 150px;
    margin-left: 10px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
}

.tab__item:hover{
    background-color: #e5e5e5;
}

.active{
    border-top: 3px solid #00aff0;
}