.blockBidLot{
    font-family: 'Segoe UI';
    width: 100%;
    height: auto;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.blockBidLot_title{
    color: #000;
    font-size: 24px;
    font-weight: 600;
}
.bidLotCost_title{
    position: absolute;
    color: #92929D;
    top: -9px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
}
.bidLotCost_input{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    color: #6d6d6d;
    font-size: 12px;
    position: relative;
}

.bidLotCost_input input{
    display: flex;
    align-items: center;
    padding: 9px 12px 8px;
    width: 480px;
    height: 40px;
    border: 1px solid #92929D;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}
.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.bidLotCost_input input:hover,
.bidLotCost_input input:focus {
    border: 1px solid #139DD9;
    transition: .2s;
    outline: none;
}
.bidLotCost_input input:hover + .bidLotCost_title,
.bidLotCost_input input:focus + .bidLotCost_title{
    color:#139DD9;
    transition: .2s;
}
.bidLotBtn{
    display: flex;
    color:#fff;
    background: #139DD9;
    border-radius: 4px;
}
.btnAddSecondBid{
    width: 100%;
}
.bidLotCost_descr{
    color: #92929D;
    font-size: 14px;
    margin: 0 0 10px;
}
.chekbox{
    width: 100%;
    height: 28px;
    /* padding-left: 30px; */
    margin-right: 15px;
    font-size: 14px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.pseudoCheckboxOn{
    width: 16px;
    height: 16px;
    border: 1px solid #92929D;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
}
.pseudoCheckboxOff{
    width: 16px;
    height: 16px;
    border: 1px solid #0062FF;
    border-radius: 4px;
    cursor: pointer;
    background-color:#0062FF;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='5' viewBox='1 -2 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.70251 2.7801C1.30947 2.39211 0.676317 2.39621 0.28833 2.78925C-0.0996578 3.1823 -0.095558 3.81545 0.297487 4.20344L2.83844 6.7117C3.25647 7.12434 3.93843 7.08954 4.31227 6.63648L8.43799 1.63648C8.78949 1.21049 8.7291 0.580209 8.30311 0.228708C7.87713 -0.122792 7.24685 -0.0624085 6.89535 0.363579L3.46543 4.52033L1.70251 2.7801Z' fill='white'/%3e%3c/svg%3e ");
    background-size: cover;
    margin-right: 15px;
}
.button{
    display: flex;
    width: 200px;
    padding: 10px 16px;
    background-color: #139DD9;
    border: 1px solid #139DD9;
    color: #fff;
    border-radius: 4px;
    font: 600 13px/18px "robotolight",Arial,sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
}
.button:hover{
    background-color: #fff;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}
.button_disabled{
    display: flex;
    width: 200px;
    padding: 10px 16px;
    background-color: #c5c5c5;
    border: 1px solid #c5c5c5;
    cursor: default;
    color: #fff;
    border-radius: 4px;
    font: 600 13px/18px "robotolight",Arial,sans-serif;
    text-transform: uppercase;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
}

/* OLD CODE CSS */

.messageBlock{
    padding: 15px 25px;
    text-align: center;
    border: 1px solid #ffd200;
    font: 400 13px/18px "robotolight",Arial,sans-serif;
    margin-top: 25px;
}

.messageBlockAuth{
    margin-bottom: 25px;
}
.bidLotCost{
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top: 20px;
}
.bidLotFile_titleWrap{
    display: flex;
    justify-content: left;
}

.bidLotFile_title{
    margin-left: 30px;
    margin-right: 630px;
    text-align: left;
    font-weight: 500;
}
.bidLotFile_helpText{
    color: #92929D;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
}


.messageBlock a{
    color: #6d6d6d;
}

a{
    /*color: #585858;*/
    text-decoration: underline;
    cursor: pointer;
}
a.instrLink {
    text-decoration-line: underline !important;
    color: #139DD9;
}

.input__wrapper {
    width: 100%;
    position: relative;
    /*margin: 15px 0;
    text-align: center;*/
}
   
.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
   
   
.input__file_button_text {
    line-height: 1;
    padding: 20px;
    /*border: 1px solid #000;*/
    border-radius: 5px;
}
   
.input__file_button {
    /*width: 100%;
    max-width: 280px;
    height: 60px;
    background: grey;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
    cursor: pointer;*/
    width: 100%;
    max-width: 280px;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    margin-left: 10px;
    text-decoration: underline;
}

.addFileForm{
    text-align: center;
}

.elemCheckbox{
    margin-left: 30px;
    text-align: left;
}

.selectType{
    margin-left: 86px;
    width: 408px;
    padding: 3px;
    margin-bottom: 30px;
}

.borderLineFile{
    width: 1140px;
    margin: auto;
    height: 1px;
    background-color: #5d5d5d;
    margin-top: 30px;
    margin-bottom: 30px;
}



.titlelAddDoc{
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.listDocument{
    display: flex;
    justify-content: space-between;
}

.bidLotCost_title_notMargin{
    margin-right: 0px;
    text-align: left !important;
}

.deleteDoc{
    cursor: pointer;
}

.buttonBlock{
    display: flex;
    justify-content: space-around;
}

.buttonBlock div{
    width: 200px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

