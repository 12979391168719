.bank{
    display: flex;
    justify-content: space-between;
    position: relative;
}

.terminuTitle{
    /*color: #585858;
    font: 400 10px/10px 'robotolight',Arial,sans-serif;
    text-transform: uppercase;
    padding: 0 0 17px;*/
    font: 400 30px/30px "robotothin",Arial,sans-serif;
    color: #000;
    text-transform: none;
}

.baseSize{
    width: 27%;
    position: absolute;
    right: 0;
}

.yellowBlock{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 10px;
    background-color:rgb(255, 210, 0);
}

.offBlock{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 10px;
}

.titleDate{
    font-weight: 600;
}

.titleDate1{
    width: 100%;
    font-weight: 600;
    display: block !important;
}

.titleDate1 span{
    width: 100% !important;
    text-align: center;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.offBlock div, .yellowBlock div{
    display: flex;
}

.offBlock div span, .yellowBlock div span{ 
    display: block;
    width: 170px;
}

.bankText{
    width: 70% !important;
}