.answerBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 24px;    
    position: static;
    width: calc(100% - 50px);
    height: auto;
    background:inherit;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    margin: 0 auto 24px;
    margin-top: 20px;
}
.title{
    margin: 10px 0 25px;
    color:#171725;
}
.question{
    width:100%;
    display:flex;
    flex-direction: column;
    margin: 0 0 24px;
}
.answer{
    width:100%;
    display:flex;
    flex-direction: column;
    margin: 0;
}
.description{
    width:100%;
    font-size: 12px;
    color: #696974;
    display:flex;
}
.subject{
    width:100%;
    font-size: 14px;
    color: #171725;
    display:flex;
}

.help{
    justify-content: flex-end;
}