.terminu{
    width: 100%;
    /* margin-top: 30px; */
    margin-bottom: 30px;
}

.terminuTitle{
    color: #585858;
    font: 400 10px/10px 'robotolight',Arial,sans-serif;
    text-transform: uppercase;
    padding: 0;
}
.mapTitle{
    font: 600 14px/14px 'robotolight',Arial,sans-serif;
}

.terminuTitle span{
    color: #000;
}

.terminuDates, .documentElem{
    border-left: 15px solid grey;
    padding-left: 10px;
}

.terminuDates:hover, .documentElem:hover{
    border-left-color: rgb(0, 175, 240);;
}

a{
    /*color: #585858;*/
    text-decoration: underline;
    cursor: pointer;
}

.documentLine{
    width: 100%;
    display: flex;
    padding: 22px 0;
    border-bottom:1px solid rgb(128, 128, 128);
}

.documentElem :last-child{
    border-bottom:0px solid rgb(128, 128, 128);
}

.documentLeft{
    display: flex;
    width: 70%;
}

.documentLeft_download{
    padding-left: 10px;
}

.download_fileName{
    font: 400 12px/20px "robotolight",Arial,sans-serif;
    color: #6d6d6d;
    padding-top: 5px;
}

.documentLeft_download div a{
    font: 400 14px/20px 'robotoregular', Arial,sans-serif;
    color: #000;
    text-decoration: underline;
}

.scladlotuname{
    text-align: left;
    color: #000;
    font: 400 14px/20px 'robotoregular', Arial,sans-serif;
    padding: 0 0 17px;
}

.scladLotuElem{
    display: flex;
    justify-content: space-around;
}

.scladLotuNumber{
    width: 5%;
    color: #9d9d9d;
    font: 400 24px/24px 'robotothin',Arial,sans-serif;
    text-align: center;
}

.scladlotunMain{
    width: 85%;
    padding-right: 10%;
}

.scladLotuColl{
    width: 10%;
}

.infoLotTitle{
    font: 400 30px/30px "robotothin",Arial,sans-serif;
    color: #000;
    text-transform: none;
}

.infoLotText{
    font: 400 14px/24px "robotolight",Arial,sans-serif;
    color: #6d6d6d;
    padding-top: 0;
    width: 70%;
    padding-bottom: 35px;
}

.tabTovar_wrap{
    background-color: #eee;
    padding-top: 15px;
    padding-bottom: 0px;
    text-transform: uppercase;
    color: #000;
    font: 400 14px/14px 'robotolight',Arial,sans-serif;
    display: flex;
}
.tabTovar_ogosha{
    background: #FFF;
    border-top: 3px solid #00aff0;
    position: relative;
    width: 150px;
    padding: 20px;
    margin-top: -17px;
    display: flex;
    justify-content: center;
    line-height: 28px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
.tabTovar_tell{
    width: 150px;
    padding: 20px;
    margin-top: -8px;
    display: flex;
    justify-content: center;
}


/****/
.terminuLine{
    width: 100%;
    display: flex;
    padding-top: 14px;
}

.terminuLineText{
    color: #6d6d6d;
    font: 400 14px/20px 'robotolight', Arial,sans-serif;
    width: 32%;
    padding-bottom: 12px;
}

.terminuLineDate{
    text-align: left;
    vertical-align: top;
    color: #000;
    font: 400 14px/20px 'robotoregular', Arial,sans-serif;
    padding: 0;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.additionalClassifications_line{
    display: flex;
}

.additionalClassifications_warap{
    display: flex;
    justify-content: space-around;
}

.map{
    width: 100%;
    height: 400px;
    border-radius: 4px;
    border: 1px solid #00aff0;
    padding: 2px;
}