.flexBlock{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
    /*align-items: center;*/
}
.group{
    margin-bottom: 20px;
}

.groupElem{
    display: flex;
    justify-self: start;
    align-items: center;
    margin: 10px;
}

.groupElem div{
    margin-right: 10px;
}

.textAreaWidth, .inputText, .selectWidth{
    width: 800px;
}

.bottonBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.flexBlockElem{
    width: 50%;
}

.auctionsLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.auctionsLineElem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonExitDocument{
    width: 300px;
}

.editButton{
    margin-right: 25px;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemsId{
    margin-right: 15px;
}


.groupElemCategory select{
    width: 742px;
}
@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}
.groupElemCategoryTwo{
    width: 700px;
}
.groupElemCategoryTwo div{
    width: 100%;
}
