.firstBlock{
    width: 100%;
    height: 46px;
    background: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header{
    font-size: 12px;
    padding-left: 20px;
    color: #585858;
}

.icons{
    width: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.obgovorennya{
    background-image: url('https://www.dto.com.ua/images/icons.png');
    width: 25px;
    height: 25px;
    background-position: -150px -0px;
}

.uchast{
    background-image: url('https://www.dto.com.ua/images/icons.png');
    width: 25px;
    height: 25px;
    background-position: -150px -30px;
}

.izbrane{
    background-image: url('https://www.dto.com.ua/images/icons.png');
    width: 25px;
    height: 25px;
    background-position: -150px -60px;
}

.inviz{
    display: none;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}