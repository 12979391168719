.headerTovar{
    display: flex;
    justify-content: space-between;
}

.headerTovarTitle{
    width: 54%;
}

.headerTovarDate{
    color: #6d6d6d;
    font: 400 14px/14px 'robotolight',Arial,sans-serif;
    padding: 0 0 7px;
}

.headerTovarText{
    padding: 0;
    color: #000;
    font: 400 36px/48px 'robotothin',Arial,sans-serif;
    text-transform: none;
}

.headerTovarStartCoast{
    width: 39%;
}

.headerTitleTovarSumm{
    margin-top: 20px;
}

.headerTovarStartCoastText{
    color: #6d6d6d;
    font: 400 14px/14px "robotolight",Arial,sans-serif;
    padding-bottom: 18px;
}

.headerTovarStartCoastSumm{
    font: 400 30px/30px 'robotolight',Arial,sans-serif;
    color: #000;
    display: flex;
    align-items: center;
}

.headerTovarStartCoastSumm span{
    font: 400 13px/13px 'robotolight',Arial,sans-serif;
    margin-left: 5px;
    color: #000;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.illustration{
    width: 250px;
}

.illustrationImg{
    width: 100%;
}