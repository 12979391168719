.secondBlock_textPrice{
    color: #6e6e6e;
    font: 400 12px/12px 'robotolight',Arial,sans-serif;
    padding-bottom: 10px;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}