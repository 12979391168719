.blueBlock{
    border: 1px solid rgb(0, 175, 240);
    color: rgb(0, 175, 240);
    padding: 10px;
    margin-left: 10px;
}

.blueBlock div{
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.blueBlock div:first-child, .blueBlock div:last-child{
    padding: 10px;
}

.blueBlock div:last-child{
    text-transform: none;
}

.blueBlock:hover{
    color: #fff;
    background-color: rgb(0, 175, 240);
}

.blueBlock_wrap{
    width: 95%;
    border-top: 1px solid rgb(0, 175, 240);
    margin: auto;
}

.blueBlock:hover .blueBlock_wrap{
    border-top: 1px solid #fff;
}

@media (min-width: 320px){}

@media (min-width: 730px){}

@media (min-width: 960px){}

@media (min-width: 1280px){}

@media (min-width: 1440px){}

@media (min-width: 1600px){}

@media (min-width: 1920px){}