.yellowBlock{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 10px;
    background-color:rgb(255, 210, 0);
}
.yellowBlock div{
    margin-bottom: 10px;
}

.bank_right{
    display: flex;
    justify-content: space-between;
}

.bank_rightBlacktext{
    width: 50%;
    margin-left: 10px;
    font: 400 11px/12px 'robotobold',Arial,sans-serif;
    text-transform: uppercase;
    text-align: left;
    color: #000;
    font-weight: 600;
}

.bank_rightRedtext{
    color: red;
    margin-left: 10px;
    font: 400 11px/12px 'robotobold',Arial,sans-serif;
    text-align: left;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}