.threeBlock_data{
    width: 100%;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}