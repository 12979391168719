.wrapMain{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9998;
}
.wrap{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 518px;
    /*height: 358px;*/
    background-color: #ffffff;
    z-index: 9999;
    margin: auto;
    font-size: 14px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}
.close{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    right:20px;
    top:20px;
    font-size: 18px;
    cursor:pointer;
    color:#92929D;
}
.close:hover{
    color:#139DD9;
    transition: .2s;
}
.popupTitle{
    font-size: 24px;
    text-align: left;
}
.description{
    margin: 8px 0 24px;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% + 20px);
    margin: 10px 0;
    padding: 16px ;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.bottomPart{
    height: 56px;
    width: 100%;
    margin: 24px 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
}
.btnDone{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    margin: 0 0 0 20px;
    width: 111px;
    height: 40px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
}
.btnDone:hover {
    transition: .2s;
    background-color: #139DD9;
    color: #ffffff;
}
.btnCancel{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
}
.btnCancel:hover {
    transition: .2s;
    background-color: #dfdfdf;
    /* color: #ffffff; */
}

/* INPUT */ 
.bidLotCost_descr{
    color: #92929D;
    font-size: 14px;
    margin: 0 0 10px;
}
.bidLotCost_title{
    position: absolute;
    color: #92929D;
    top: -9px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
}
.bidLotCost_input{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    color: #6d6d6d;
    font-size: 12px;
    position: relative;
    margin: 10px 0 0;
}

.bidLotCost_input input{
    display: flex;
    align-items: center;
    padding: 9px 12px 8px;
    width: 480px;
    height: 40px;
    border: 1px solid #92929D;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}
.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.bidLotCost_input input:hover,
.bidLotCost_input input:focus {
    border: 1px solid #139DD9;
    transition: .2s;
    outline: none;
}
.bidLotCost_input input:hover + .bidLotCost_title,
.bidLotCost_input input:focus + .bidLotCost_title{
    color:#139DD9;
    transition: .2s;
}