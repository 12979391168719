.tabTovar_wrap{
    background-color: #eee;
    padding-top: 15px;
    padding-bottom: 0px;
    text-transform: uppercase;
    color: #000;
    font: 400 14px/14px 'robotolight',Arial,sans-serif;
    display: flex;
}
.tabTovar_ogosha{
    background: #FFF;
    border-top: 3px solid #00aff0;
    position: relative;
    width: 150px;
    padding: 20px;
    margin-top: -17px;
    display: flex;
    justify-content: center;
    line-height: 28px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
.tabTovar_tell{
    width: 150px;
    padding: 20px;
    margin-top: -8px;
    display: flex;
    justify-content: center;
}

.tabTovar_ogosha a, .tabTovar_tell a{
    color: #6d6d6d;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}