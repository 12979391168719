.secondBlock{
    width: 97%;
    margin: auto;
    margin-top: 17px;
    display: flex;
    justify-content: space-around;
}

.secondBlock_text{
    width: calc(60% - 40px);
    padding-right: 40px;
}

.secondBlock_cost{
    width: 40%;
}

.secondBlock_textPrice{
    color: #6e6e6e;
    font: 400 12px/12px 'robotolight',Arial,sans-serif;
    padding-bottom: 10px;
}

.secondBlock_numberPrice{
    color: #585858;
    font: 400 14px/14px 'robotolight',Arial,sans-serif;
    display: flex;
    align-items: center;
}

.secondBlock_numberPrice span{
    font: 400 48px/48px 'robotothin',Arial,sans-serif;
    color: #000;
    margin-right: 10px;
}

.secondBlock_headTovar{
    font: 400 16px/24px 'robotobold',Arial,sans-serif;
    color: #585858;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
}

.threeBlock{
    width: 97%;
    height: 90px;
    margin: auto;
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    display: flex;
}

.identif{
    width: 100%;
    display: flex;    
}

.identif div{
    width: 49%;
}

.threeBlock_text{
    display: flex;
    width: 60%;
}

.secondBlock_img{
    width: 151px;
    margin-right: 10px;   
}

.secondBlock_img img{
    width: 100%;
}

.threeBlock_data{
    width: 100%;
}

.secondBlock_textPrice span{
    color: #000;
}

.secondBlock_textBread{
    background: #96999b;
    color: #fff;
    font: 400 13px/13px 'robotoregular',Arial,sans-serif;

    display: block;
    width: 42%;
    text-align: left;
    padding: 10px;
    text-decoration: none;
    position: relative;
}

.textEnd{
    text-align: end;
}

.secondBlock_textAuk{
    background: #ffd200;
    color: #fff;
    font: 400 13px/13px 'robotoregular',Arial,sans-serif;

    display: block;
    text-align: left;
    padding: 10px;
    text-decoration: none;
    position: relative;
}

.secondBlock_costButton{
    width: 40%;
}

.identif_three .secondBlock_textBread, .identif_three .secondBlock_textAuk{
    width: 40%;
    cursor: pointer;
    text-transform: uppercase;
}

.identif_three{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.secondBlock_textBread{
    color: #fff;
    font-weight: 600;
}

.secondBlock_textAuk{
    color: #000;
    font-weight: 600;
}

.secondBlock_textBread div{
    position: absolute;
    top: 8px;
    right: 10px;
    width: 9px;
    height: 13px;
    background: url('https://www.dto.com.ua/images/icons.png') -130px -104px no-repeat;
}

.secondBlock_textAuk div{
    position: absolute;
    top: 8px;
    right: 10px;
    width: 15px;
    height: 16px;
    background: url('https://www.dto.com.ua/images/icons.png') -150px -124px no-repeat;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}