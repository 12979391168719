.threeBlock{
    width: 97%;
    /*height: 90px;*/
    margin: auto;
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    display: flex;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}