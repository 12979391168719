.wrapProcedure{
    width: 1176px;
}


.procedureMainBlock{
    width: 1176px;
    display: flex;
    justify-content: space-between;    
}
.procedureLeftBlock{
    width: 396px;
}
.procedureRightBlock{
    width: 780px;
    background-color: #fff;
}