.terminuLine{
    width: 100%;
    display: flex;
    padding-top: 14px;
}

.terminuLineText{
    color: #6d6d6d;
    font: 400 14px/20px 'robotolight', Arial,sans-serif;
    width: 32%;
    padding-bottom: 12px;
}

.terminuLineDate{
    text-align: left;
    vertical-align: top;
    color: #000;
    font: 400 14px/20px 'robotoregular', Arial,sans-serif;
    padding: 0;
}

.grey{
    color: #6d6d6d;
}

.terminuLineTextLink{
    font: 400 14px/20px 'robotolight', Arial,sans-serif;
    width: 66%;
    padding-bottom: 12px;
    
}
.terminuLineTextLink a{
    text-decoration: underline !important;
    font-weight: 600;
    color: #00aff0 !important;
    cursor: pointer;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}