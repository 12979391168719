.bank_right{
    display: flex;
    justify-content: space-between;
}

.bank_rightGreytext{
    width: 50%;
    margin-left: 10px;
    color: #7c8993;
    font: 400 11px/12px 'robotobold',Arial,sans-serif;
    text-transform: uppercase;
    text-align: left;
    padding-left: 20px;
    margin-bottom: 10px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}