.secondBlock_cost{
    width: 40%;
}

.secondBlock_textPrice{
    color: #6e6e6e;
    font: 400 12px/12px 'robotolight',Arial,sans-serif;
    padding-bottom: 10px;
}

.secondBlock_numberPrice{
    color: #585858;
    font: 400 14px/14px 'robotolight',Arial,sans-serif;
    display: flex;
    align-items: center;
}

.secondBlock_numberPrice span{
    font: 400 48px/48px 'robotothin',Arial,sans-serif;
    color: #000;
    margin-right: 10px;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}