.flexBlock{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
    /*align-items: center;*/
}
.group{
    margin-bottom: 20px;
}

.groupElem{
    display: flex;
    justify-self: start;
    align-items: center;
    margin: 10px;
}

.groupElem div{
    margin-right: 10px;
}

.textAreaWidth, .inputText, .selectWidth{
    width: 490px;
}

.bottonBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.textLot{
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
}

.textLot a{
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
}

.textLot span{
    font-size: 16px;
    font-weight: 500;
    color: #666;
}

.documentBlock{
    border: 1px solid #000;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}