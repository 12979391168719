.secondBlock_headTovar{
    font: 400 16px/24px 'robotobold',Arial,sans-serif;
    color: #585858;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
}

.secondBlock_headTovar a{
    font: 400 16px/24px 'robotobold',Arial,sans-serif;
    color: #585858;
}

@media (min-width: 320px){}
@media (min-width: 730px){}
@media (min-width: 960px){}
@media (min-width: 1280px){}
@media (min-width: 1440px){}
@media (min-width: 1600px){}
@media (min-width: 1920px){}