.terminu{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
}

.documentElem{
    border-left: 15px solid grey;
    padding-left: 10px;
}

.documentElem:hover{
    border-left-color: rgb(0, 175, 240);;
}

.bidLotCost{
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top: 20px;
}

.bidLotCost_title{
    margin-left: 30px;
    margin-right: 60px;
    color: #6d6d6d;
}

.bidLotCost_input{
    color: #6d6d6d;
    font-size: 12px;
}

.bidLotCost_input input{
    width: 400px;
    border: 1px solid #5d5d5d;
    font-size: 14px;
    padding: 3px;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.selectType{
    margin-left: -10px;
    width: 408px;
    padding: 3px;
}

.inputTextArea{
    width: 400px;
    height: 150px;
}

.answerButton{
    width: 407px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 205px;
    background-color: rgb(0, 175, 240);
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}