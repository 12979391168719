.leaseDuration_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 450px;
    margin-top: 20px;
}

.leaseDuration_data_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}

.leaseDuration_data{
    margin-top: 3px;
    margin-bottom: 3px;
}

.leaseDuration_data_wrap div input{
    width: 50px;
    border: 1px solid #000;
    margin-top: 2px;
}

.regionArea_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1020px;
}

.area_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:520px;
    margin-top: 20px;
}

.area_data_wrap{
    width: 240px;
}

.area_data_wrap input{
    width: 240px;
    margin-right: 20px;
}

.region_wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:480px;
    margin-top: 20px;
}

.region_data_wrap{
    width: 240px;
}

.region_data_wrap select{
    border: 1px solid #000;
    line-height: 24px;
    padding: 5px;
    height: 36px;
}

.classification_wrap{
    display: flex;
    align-items: center;
    width:1100px;
}
.classification_wrap div select{
    border: 1px solid #000;
    line-height: 24px;
    padding: 5px;
    height: 36px;
    width: 480px !important;
}

.classification_wrap div select.dop{
    width: 535px !important;
}

.addInputs{
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.addInputs .area_data_wrap{
    margin-right: 20px;
}