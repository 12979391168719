@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.infoBlocks {
    font-family: 'Segoe UI';
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}

body {
    background: #FAFAFB;
    font-family: 'Segoe UI';
}

h1 {
    margin: 10px 0 20px 0;
    font-weight: 600;
}
h3, h2 {
    margin: 8px 0 20px 0;
    font-weight: 600;
}
 
.wrap  {
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 10px 30px 10px 10px;
    /* border: 1px solid rgb(223, 223, 223); */
    box-sizing: border-box;
}
.mainBlock {
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.auctionTitle ul {
    padding:0px;
    margin:10px 0;
    font-size: 14px;
    color: #171725;
}
.auctionTitle ul li{
    display: inline-block;
    cursor: pointer;
}
.auctionTitle ul li:last-child {
    color: #92929D;
}
.aucProperties {
    width: 780px;
    height: auto;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.aucProperties div:last-child{
    margin-bottom:0;
}
.aucNav {
    position: relative;
    height: 50px;
    margin-top: 32px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;

}
.aucNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
}
.activeNavItem {
    color: #139DD9;
}
.activeNavItem.aucNavItem::before{
    position: absolute;
    height: 5px;
    width: 100%;
    top: 45px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
/* info BLOCK */
.infoBlock {
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.infoBlock p, .draftProposal p {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    color: #171725;
}
.infoBlock p.desc, .draftProposal  p.desc {
    font-family: 'Roboto';
    font-size: 10px;
    color: #696974;
    font-style: normal;
    margin: 0;
}
.infoBlock h2,.infoBlock h3,
.draftProposal h2{
    width:70%;
}
.attention {
    border:1px solid #FFD200;
}
/* Organizator info */
{
    width:100%;
    margin-bottom: 10px;
}
/* LOT INFO */
.orgTitle,
.orgAdress,
.lotPrice,
.lotDiscr,
.lotFam,
.lotReqirements,
.lotOther {
    width:100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.orgContact,
.orgId,
.lotNumber,
.lotType,
.lotOrder,
.lotID,
.lotCondition,
.lotMinStatement,
.lotMinStep,
.lotGaranty {
    width:50%;
    margin-bottom: 10px;
    padding-right: 20px;
    box-sizing: border-box;
}
/* LOT COMPOSITION */
.lotFull {
    width:100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.lotClassification,
.lotCodeCPVS,
.lotHalf {
    width:50%;
    margin-bottom: 10px;
    padding-right: 20px;
    box-sizing: border-box;
}
.downLoadAllBtn{
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color:#696974;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    margin-left: auto;
    cursor: pointer;
}
.infoSubBlock {
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.downLoadBtn {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
}
.downLoadBtn:hover,.downLoadAllBtn:hover {
    border: 1px solid #139DD9;
}
.downLoadBtn:hover .svgIcon {
    fill:#139DD9;
}
ul.fileList{
    padding:0px;
    margin:0px 0;
    font-size: 14px;
    color: #171725;

}
ul.fileList li{
    display: inline-block;
    cursor: pointer;
    color:#139DD9;
    text-decoration: underline;
}
.lotImageWrap{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.lotImage{
    position: relative;
    width: 150px;
    height: 179px;
}
.lotImage p{
    font-size: 14px;
    color:#139DD9;
    cursor: pointer;
    text-decoration: underline;
}
.lotImage img{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    cursor: pointer;
}
.imgHover{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.imgHover:hover{
    display: flex;
    z-index: 9999;
    background-color: rgba(146, 146, 157, .5);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    border-radius: 4px;
    transition:.2s;
}
.imgHover:hover .zoomIcon{
    display: flex;
}
.zoomIcon{
    position: relative;
    display:none;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background:#139DD9;
    border-radius:50%;
}
table{
    width: 100%;
    height:auto;
    padding: 0px;
}
table td {
    padding: 12px;
    font-size: 14px;
    vertical-align: top;
}
.tableLotID{
    font-weight: 600;
}
.tableTradeStatus{
    text-transform: uppercase;
    font-weight: 600;
}
.colorDisabledGrey{
    color:#BFBFC6;
}

/*  draftProposal */
.draftProposal{
    border: 1px solid #139DD9;
    border-radius: 4px;
    width:100%;
    padding:24px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
}
p.status{
    text-transform: uppercase;
    font-weight: 600;
    color:#BFBFC6;
}
.svgChevronUP {
    width: 12px;
    margin-left: auto;
    margin-bottom: 5px;
    cursor: pointer;
}
.addDoc {
    border: 1px solid #139DD9;
    border-radius: 4px;
    width:100%;
    padding:24px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
}
.addInfo {
    width: 32px;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
}
.addInfoIcon{
    width: 32px;
    height: 32px;
    background-color: #FFD200;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.addDocInsturuction {
    /* display: flex; */
    position: relative;
    width: 624px;
    position: relative;
    height: auto;
    padding-left: 24px;
    flex-flow: row wrap;
}
.addDocInsturuction h3{
    display: inline-flex;
    width: 200px;
    margin: 0 0 0 0;
    height: 32px;
}
.addDocInsturuction .aCollapse {
    display: inline-flex;
    position: absolute;
    right: 0;
    margin: 0 0 0 0;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
}
.addDocInsturuction ul{
    padding: 0 0 0 10px;
    list-style-type: '- ';
    font-size: 14px;
    margin:0;
}
.addDocInsturuction ul li{
    margin-left: 10px;
}
.iconBtnBlock {
    display: flex;
    margin-left: auto;
}
.editIcon, .deleteIcon {
    width: 38px;
    height: 38px;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}
.editIcon {
    border: 1px solid #E2E2EA;
}
.deleteIcon {
    border: 1px solid #FFA047;
}
.svgChevronUP{
    transform:'rotate(180deg)';
}
.eSign{
    width:100%;
}
.signTitle{
    display:flex;     
    align-content:space-between; 
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 13px;
}
.signContainer{
    position: relative;
    width: 100%;
    border-top: 1px solid #E2E2EA;
    padding: 24px 0 12px 0;
}
p.signStatus{
    display: block;
    font-size: 14px;
    color: #92929D;
    position: absolute;
}
.signContainer svg{
    /* display: block; */
    /* position: relative; */
    margin: 0px 10px 0px 0px;
}
.signIcon{
    width: 80%;
    height: 20px;
    margin: 10px 0;
    font-size: 14px;
    color: #92929D;
    display: flex;
    align-items: center;
}
.signBtn {
    position: absolute;
    right: 0px;
    bottom: 12px;;
    width: 111px;
    height: 32px;
    background: #139DD9;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}
.docBlock{
    display:none;
    transition: 1s;
}
#addDoc{
    cursor: pointer;
}
.Btn {
    /* width: auto; */
    height: 40px;
    padding: 10px;
    margin: 20px 0;
    cursor:pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}
.addDocBtn{
    width: 148px;
    height: 40px;
    /* background: #FFFFFF; */
    border: 1px solid #00AFF0;
    color: #139DD9;
}
.chkAgree{
    margin: 20px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #171725;
}
.chkAgree input {
    margin-right: 10px;
    width: 14px;
    height: 14px;
    background: #B5B5BE;
}

.deleteBtn{
    width: 180px;
    height: 40px;
    border: 1px solid #FFA047;
    color: #FFA047;
    margin: 0px;
}

.editBtn{
    width: 105px;
    height: 40px;
    border: 1px solid #E2E2EA;
    color: #696974;
    margin: 0px 0 0 auto;
}
.editIcon:hover{
    border: 1px solid #00AFF0;
    transition: .2s;
}
.editIcon:hover .svgIcon{
    fill:#00AFF0;
    transition: .2s;
}
.sendBtn{
    width: 169px;
    height: 40px;
    background-color: #139DD9;
    color: #FAFAFB;
    margin: 0px 0 0 8px;
    justify-self: flex-start;
}
.BottomBtns{
    display: flex;
}
.sideBlock {
    width: 370px;
    height: auto;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    /* background-color: forestgreen; */
    padding: 24px 0 0 24px;
}
.sidelotImage{
    display: ;
    width: 100%;
}
.sidelotImage img{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    cursor: pointer;
}
.aucStatus{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 15px;
}
.aucStatus p {
    margin-bottom: 0px;
}
p.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 8px;
    color: #00B191;
}
.lotStartPriceBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotStartPriceBlock p{
    margin: 0 0;
}
p.lotStartPrice{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin: 0 0;
}
.lotStartPrice span{
    font-size: 14px;
    color: #171725;
}
.stepBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    margin: 25px 0 5px 0;
}
.stepIcon{
    width: 36px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    box-sizing: border-box;
    padding-top: 0px;
}
.stepInfo{
    width: 266px;
    padding-left: 24px;
    box-sizing: border-box;
}
.stepInfo h3{
    margin: 0px 0 5px 0;
    width: 266px;
}
.stepInfo h3.inactive{
    color:#92929D;
    font-weight: 600;
}
.stepInfo p{
    font-size: 14px;
    color: #92929D;
    margin: 0px 0 0 0;
}
.balance{
    margin-top: 30px;
}
.balance p {
    width: 322px;
    font-size: 14px;
    line-height: 19px;
    color: #171725;
    margin: 0;
}
.balance h2 {
    margin: 0 0 10px 0;
}
.balanceBtn{
    width: 169px;
    height: 40px;
    border: 1px solid #139DD9;
    color: #139DD9;
    margin: 25px 0 0 0px;
    justify-self: flex-start;
}

.wrapPeriod{
    width: 682px;
}

.wrapPeriod_mainDate{
    width: 682px;
}

.wrapPeriod_mainDate{
    width: 682px;
    display: flex;
}

.schedule{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}