.answerWrap{
    border: 1px solid #c5c5c5;
    padding: 15px;
    margin: 10px;
}

.answerWrap_line{
    font-size: 18;
}

.answerWrap_line span{
    color: dimgray;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.nullBlock{
    width: 100%;
    height: 350px;
}

.answerBlock{
    width: 73%;
}