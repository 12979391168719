.aukpoisk_first{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
}

.aukpoisk_four_second_wrap{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.aukpoisk_four_second_wrap div{
    /*width: 200px;*/
    color: #585858;
    font: 400 10px/10px 'robotolight',Arial,sans-serif;
    text-transform: uppercase;
    /*padding-top: 13px;*/
}

.aukpoisk_four_second_wrap select, .aukpoisk_four_second_wrap input{
    width: 400px;
    font: 400 14px/24px 'robotocondenced',Arial,sans-serif;
    padding: 5px;
}

.aukpoisk_four_second_wrap input{
    width: 180px;
    font: 400 14px/24px 'robotocondenced',Arial,sans-serif;
    padding: 5px;
}

.validfilterBlock{
    /*border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;*/
    padding-top: 0px !important;
    width: 750px !important;
}

.validfilterBlock input{
    padding: 1px;
    padding-left: 4px;
    width: 750px;
    outline:0 !important;
    padding-bottom: 2px;
    padding-top: 3px;
    border-right: 0px solid #000;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.validfilterBlockS{
    width: 200px !important;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    padding-top: 0px !important;
}

.validfilterBlockSK{
    border: 1px solid #000;
    padding-top: 0px !important;
    margin-left: 20px;
}

.validfilterSelect{
    width: 200px !important;
    margin-right: 10px;
    border: 0px;
    outline:0 !important;
}

.findStart{
    margin-left: 20px;
    padding: 10px 0;
    background: #e5e5e5;
    cursor: pointer;
    color: #000;
    font: 400 12px/12px 'robotobold',Arial,sans-serif !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    height: 10px;
}

.findClear{
    width: 50px !important;
    margin-left: 20px;
    padding: 10px 0;
    background: #e5e5e5;
    cursor: pointer;
    color: #000;
    font: 400 12px/12px 'robotobold',Arial,sans-serif !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
    height: 10px;
}

.findStart a{
    color: #585858;
}

.buttonSearch{
    /*display: flex;
    height: 100px;*/
}

.searchElem{
    width: 960px !important;
}

.searchElem_line{
    display: flex;
    width: 750px;
    justify-content: space-between;
    margin-bottom: 30px;
    /*height: 33px;*/
}

div.dateSearch{
    /*width: 350px !important;*/
    /*margin-right: 20px !important;*/
    margin-right: 10px !important;
}

div.dateSearch div{
    /*width: 350px !important;*/
}
.dateSearch input{
    /*width: 350px !important;*/
}

.buttonSearch .findStart{
    width: 200px !important;
}

.linemid{
    width: 1200px;
    display: flex;
    justify-content: space-between;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}